/*
    Utils for form validation
*/

frappe.provide("frappe.form.utils");

$.extend(frappe.form.utils, {
    
    show_mobile_sidebar_animation: function($wrapper){

        frappe.form.utils.reset_animation($wrapper);
        if(frappe.form.utils.is_mobile()){
            $wrapper.find(".form-sidebar").show().delay(3600).fadeOut(600);
        }
    },

    is_mobile: function(){
        if ($(window).width() < 991) {
            return true;
        }
    },

    reset_animation: function($wrapper){
        
        $wrapper.find(".animation span").each(function() {
            var me = this;
            this.style.webkitAnimation = 'none';
            setTimeout(function() {
                me.style.webkitAnimation = '';
            }, 10);
        });
        $wrapper.find(".sidebar-description").each(function() {
            var me = this;
            this.style.webkitAnimation = 'none';
            setTimeout(function() {
                me.style.webkitAnimation = '';
            }, 10);
        });
    }
});