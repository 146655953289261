/*
    Website Settings module
*/

frappe.provide("bond_assets.website_settings");

$.extend(bond_assets.website_settings, {

    get_client_application_timeout(){
        return frappe.boot.client_portal_settings.client_application_timeout || 20;
    }
});