/*
    Utilities function
*/
frappe.provide("portal.utils.numbers");

$.extend(portal.utils.numbers, {

    generate_future_years: function(range){
        let from_year = moment().year();
        let to = from_year + 50;
        if(range){
            to = from_year + range;
        }
        return portal.utils.numbers.generate_numbers(from_year, to);
    },

    generate_past_years: function(range){
        let to = moment().year();
        let from = to - 50;
        if(range){
            from = to - range;
        }
        return portal.utils.numbers.generate_numbers(from, to);
    },
    generate_numbers: function(from, to){
        let data = [];
        for(var i=from;i<=to; i++){
            data.push(i);
        }
        return data;
    }
});
