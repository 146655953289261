

import "frappe/public/js/frappe/class.js";
import "frappe/public/js/frappe/polyfill.js";
import "frappe/public/js/frappe/provide.js";
import "frappe/public/js/frappe/translate.js";
import "bond_assets/public/js/vue/init.js";
import "frappe/public/js/frappe/format.js";
import "bond_assets/public/js/utils/phonenumbers.js";
import "bond_assets/public/js/utils/form.js";
import "frappe/public/js/frappe/utils/user.js";
import "frappe/public/js/frappe/utils/common.js";
import "frappe/public/js/frappe/utils/urllib.js";
import "frappe/public/js/frappe/utils/pretty_date.js";
//import "frappe/public/js/frappe/utils/test_utils.js";
import "frappe/public/js/frappe/utils/tools.js";
import "frappe/public/js/frappe/utils/datetime.js";
import "frappe/public/js/frappe/utils/number_format.js";
import "frappe/public/js/frappe/utils/help.js";
import "frappe/public/js/frappe/utils/help_links.js";
import "frappe/public/js/frappe/utils/address_and_contact.js";
import "frappe/public/js/frappe/utils/preview_email.js";
import "frappe/public/js/frappe/utils/file_manager.js";
import "frappe/public/js/frappe/utils/utils.js";
import "frappe/public/js/frappe/utils/pretty_date.js";
import "frappe/public/js/frappe/microtemplate.js";
import "frappe/public/js/frappe/query_string.js";

//import "frappe/public/js/frappe/ui/dropzone.js";
//import "frappe/public/js/frappe/ui/upload.html";
import "frappe/public/js/frappe/model/meta.js";
import "frappe/public/js/frappe/model/model.js";
import "frappe/public/js/frappe/model/perm.js";

import"frappe/public/js/frappe/socketio_client.js";

import "bond_assets/website/js/website.js";
import "bond_assets/public/js/website_settings.js";

//import "frappe/public/js/frappe/utils/rating_icons.html";

import "bond_assets/public/js/utils/datetime.js";

import "frappe/public/js/frappe/utils/datetime.js";

import "frappe/public/js/frappe/form/formatters.js";

import "bond_assets/public/js/utils/formatters.js";
import "bond_assets/public/js/utils/timerange.js";
import "bond_assets/public/js/utils/company_schedule.js";
import "bond_assets/public/js/utils/numbers.js";