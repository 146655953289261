/*
    all the formatter utilities function will come here.
*/


// added missing formatter utility functions
Object.assign(frappe.utils, {
    format: (string, object) => {
        for (const key in object)
            string  = string.replace(`{${key}}`, object[key])
    
        return string
    }
});