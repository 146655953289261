/*
    Time Range Controller
*/

frappe.provide("bond_assets.utils.timerange");

$.extend(bond_assets.utils.timerange, {
    default_datetime_label_format: "MMM DD, YYYY HH:mm A",
    default_date_label_format: "MMM DD, YYYY",
    default_time_label_format: "HH:mm A",
    generate_schedule: function(start_datetime, end_datetime, interval){
        if(!interval)
            interval = 30;

        let start_time = moment(start_datetime);
        let end_time = moment(end_datetime);
        let _start = start_time;
        let slot_time=null, hour=null, temp={};

        let schedules = [];
        
        while(start_time <= end_time){
            _start = moment(start_time.clone());
            start_time = moment(_start.clone().add(interval, company_schedule.utils.min_internal));
            hour = _start.format("HH");

            if(hour >= 0 && hour < 12){
                slot_time = "morning";
            }
            else if(hour >= 12 && hour < 18){
                slot_time = "afternoon";
            }else{
                slot_time = "evening";
            }
            temp = {
                "start_time": _start,
                "end_time": start_time,
                "str_start_time": frappe.datetime.get_datetime_as_string(_start),
                "str_end_time": frappe.datetime.get_datetime_as_string(start_time),
                "weekday": _start.format("ddd"),
                "date": _start.date(),
                "slot_time": slot_time,
                "start_label": _start.format(bond_assets.utils.timerange.default_datetime_label_format),
                "end_label": start_time.format(bond_assets.utils.timerange.default_datetime_label_format),
            };
            schedules.push(temp);

        }
        return schedules;
    },

    get_hourly_schedule: function(start_datetime, end_datetime){
        return bond_assets.utils.timerange.generate_schedule(start_datetime, end_datetime, 60);
    },

    get_half_hourly_schedule: function(start_datetime, end_datetime){
        return bond_assets.utils.timerange.generate_schedule(start_datetime, end_datetime, 30);
    },

    get_default_start_and_end_datetime: function(selected_date){
        let start_datetime = moment(selected_date).add('hours', 9);
        let end_datetime = moment(start_datetime.clone()).add("hours", 9);
        return [start_datetime, end_datetime];
    },

    show_schedules: function(schedules, $wrapper){
        return new Promise((reject, resolve)=>{
            try{
                $wrapper.empty();
                let item = null;
                for(var i=0; i<schedules.length,item=schedules[i]; i++){
                    $(`<div class="form-check">
                        <input type="radio" class="form-check-input" id="selectschedule" name="selectschedule"
                        data-start-time="${item.str_start_time}" data-end-time="${item.str_end_time}">
                        <label class="form-check-label" for="materialUnchecked">${item.start_label}</label>
                </div>`).appendTo($wrapper);
                }
                resolve($wrapper);
            }catch(e){
                reject(e);
            }
        });
    }
});