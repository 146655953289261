/*
    Company schedule
*/
frappe.provide("company_schedule.utils");

$.extend(company_schedule.utils, {

    min_internal: "minutes",
    get_company_schedule: function(){
        return new Promise((resolve, reject)=>{
            frappe.xcall("bond_assets.bond_assets.company_schedule.get_company_schedule",{
            }).then((res)=>{
                return resolve(res)
            });
        });
    },

    is_available: function(){
        let day = moment(frappe.datetime.now_date()).format("ddd");
        let schedule = frappe.boot.company_schedule;
        if(schedule && schedule.is_available){
            return true;
        }
        return false
    },

    is_still_available(){
        let is_available = company_schedule.utils.get_system_and_client_datetime_diff("minutes", true);
        if(is_available && is_available > 1){
            // if a minute left to end the shift
            return true;
        }
        return false;
        
    },

    get_system_and_client_datetime_diff(unit, with_fraction=false){
        let end_time = company_schedule.utils.get_today_schedule_end_time();
        if(!end_time)
            return
        
        return moment(end_time)
            .diff(company_schedule.utils.get_client_now_datetime(), unit, with_fraction);
    },

    get_today_schedule_end_time(){
        return company_schedule.utils.get_schedule_for_today().end_time;
    },

    get_schedule_for_today: function(){
        return frappe.boot.company_schedule.today_schedule;
    },

    get_client_now_datetime: function(as_obj = false) {
		return company_schedule.utils._date(frappe.defaultDatetimeFormat, as_obj, company_schedule.utils.get_client_timezone());
	},

	_date: function(format, as_obj = false, timezone="") {
		const time_zone = timezone;
		let date;
		if (time_zone) {
			date = moment.tz(time_zone);
		} else {
			date = moment();
		}
		if (as_obj) {
			return frappe.datetime.moment_to_date_obj(date);
		} else {
			return date.format(format);
		}
	},

    get_default_timezone(){
        return "America/New_York";
    },

    get_client_timezone(){
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },

    get_schedule_for_the_day: function(day){
        let schedule = company_schedule.utils.get_schedule(day);
        return schedule[day]?schedule[day]: {};
    },

    get_schedule: function(){
        let schedule = {};
        if(frappe.boot.company_schedule){
            schedule = frappe.boot.company_schedule;
        }
        return schedule;
    },

    get_hourly_slots: function(daily_schedule){
       return company_schedule.utils._get_slots(daily_schedule, 60);
    },

    get_half_hourly_slots: function(daily_schedule){
        return company_schedule.utils._get_slots(daily_schedule, 30);
    },

    _get_slots: function(daily_schedule, interval){
        let schedule = {
            "morning": [],
            "afternoon": [],
            "evening": [],
            "total_schedule": []
        };

        let start_time = moment(daily_schedule.start_time);
        let end_time = moment(daily_schedule.end_time);
        let _start = start_time;
        let slot_time=null, hour=null, temp={};
        let now_time = moment(frappe.datetime.now_datetime())

        while(start_time <= end_time){
            _start = moment(start_time.clone());
            start_time = moment(_start.clone().add(interval, company_schedule.utils.min_internal));
            hour = _start.format("HH");
            if(start_time.isAfter(now_time)){
                if(hour >= 0 && hour < 12){
                    slot_time = "morning";
                }
                else if(hour >= 12 && hour < 18){
                    slot_time = "afternoon";
                }else{
                    slot_time = "evening";
                }
                temp = {
                    "start_time": _start,
                    "end_time": start_time,
                    "str_start_time": frappe.datetime.get_datetime_as_string(_start),
                    "str_end_time": frappe.datetime.get_datetime_as_string(start_time),
                    "weekday": _start.format("ddd"),
                    "date": _start.date(),
                    "month": _start.format("MMMM"),
                    "slot_time": slot_time
                };
                schedule[slot_time].push(temp);
                schedule.total_schedule.push(temp);
            }

        }
        return schedule;
    },

    get_available_schedule: function(){
        let schedule = [];
        $.each(frappe.boot.company_schedule.next_available_schedule.as_list, (idx, item)=>{
            if(item.is_available){
                schedule.push({
                    "start_time": moment(item.start_time),
                    "end_time": moment(item.end_time),
                    "str_start_time": frappe.datetime.get_datetime_as_string(item.start_time),
                    "str_end_time": frappe.datetime.get_datetime_as_string(item.end_time),
                    "weekday": item.weekday,
                    "date": item.date,
                    "month": moment(item.start_time).format("MMMM"),
                    "long_weekday": item.long_weekday
                })
            }
        });
        return schedule;
    },

    get_available_schedule_map: function(){
        let schedule = {};
        $.each(frappe.boot.company_schedule.next_available_schedule.as_list, (idx, item)=>{
            schedule[item.weekday] = {
                "start_time": moment(item.start_time),
                "end_time": moment(item.end_time),
                "tz_start_time": item.start_time,
                "tz_end_time": item.end_time,
                "str_start_time": frappe.datetime.get_datetime_as_string(item.start_time),
                "str_end_time": frappe.datetime.get_datetime_as_string(item.end_time),
                "weekday": item.weekday,
                "date": item.date,
                "month": moment(item.start_time).format("MMMM"),
                "long_weekday": item.long_weekday
            };
        });
        return schedule;
    }
});
