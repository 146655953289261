/*
    Phone Number lib
*/
frappe.provide("bond_assets.phonenumbers");

import parsePhoneNumber from 'libphonenumber-js'

$.extend(bond_assets.phonenumbers, {
    format_to_local: function(phone_number){
        let temp = '';
        let ph = parsePhoneNumber(phone_number, "US");
        try{
            temp = ph.formatNational();
        }catch(e){};

        return temp;
    },

    format_to_international: function(phone_number){
        try{
            let temp = '';
            let ph = parsePhoneNumber(phone_number, "US");
            temp = ph.formatInternational();
            return temp;
        }catch(e){}
        return '';
    },

    is_phone_valid: function(phone_no){
        let phoneNumber = parsePhoneNumber(phone_no, 'US');
        if(phoneNumber){
            return phoneNumber.isValid();
        }
        return false;

    }
});